// themecolor
$themecolor: #ffc831;

$primarybtn: #ffc831;

$white: #fff;

$mate-black: #252525;

$black: #474646;

$gradient-one: #ffc831;

$gradient-two: #ffc831;

$footer-back-color:#171717;

$header-top-color:#171717;

$breadcrumbs-color:#171717;
