[dir="rtl"] {
    .login__sign__form {
      margin-right: 0;
      margin-left: 10px;
    }

    .slider1__btn{
        margin-left: 10px!important;
        margin-right: 0px!important;
    }

    .playstore_img{
        margin-left: 10px!important;
        margin-right: 0!important;
    }

    .noti_desc{
        margin-right: 10px;
    }
}