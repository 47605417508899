@use "../common/breakpoints" as *;
@use "../common/mixin" as *;
@use "../common/color" as *;

.Bookmark {
  padding-bottom: 50px;

  @media #{$max-wid-device} {
    margin-top: 0;
  }

  .Breadcrumb {
    @media #{$max-wid-device} {
      margin-top: 150px;
    }
  }
  .morphisam {
    border-radius: 46px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    background-color: #fff;
    padding: 30px;
    position: relative;

    @media #{$small-device} {
      padding: 15px;
    }

    &::after {
      position: absolute;
      content: "";
      width: 90%;
      height: 30px;
      bottom: -5px;
      z-index: -1;
      background-color: $mate-black;
      border-radius: 30px;
      left: 0;
      right: 0;
      margin: auto;

      @media #{$small-device} {
        width: 80%;
      }
    }

    @media #{$max-wid-device} {
      padding: 50px 15px;
    }

    @media #{$small-device} {
      padding: 15px;
    }

    .flex-column {
      border: 1px solid #eeeeee2b;
      padding: 10px;
      margin-bottom: 10px;
      border-radius: 10px;
    }

    .tab-content {
      @extend .flex-column;
    }

    .nav-item {
      margin-bottom: 15px;
      cursor: pointer;
    }

    .nav-pills {
      .nav-link {
        color: $mate-black;
        text-align: center;
        &.active {
          background-color: $themecolor;
          color: $black;
          @include normal-transition;
        }
      }
      .show {
        > .nav-link {
          background-color: $themecolor;
          @include normal-transition;
        }
      }
    }

    .outer__stage {
      color: $mate-black;
      margin-bottom: 30px;
      @media #{$small-device} {
        margin-bottom: 15px;
      }

      .three__stage {
        display: flex;
        justify-content: space-around;
        align-items: center;

        @media #{$small-device} {
          flex-direction: column;
          text-align: center;
          justify-content: center;
        }

        .number_stage {
          span {
            background-color: $themecolor;
            width: 50px;
            height: 50px;
            border-radius: 50%;
            @include flex-center;
            font-size: 20px;
            font-weight: 600;
            color: $white;
          }
        }

        .content_stage {
          width: 800px;

          @media #{$desktop-device} {
            width: 600px;
          }

          @media #{$small-device} {
            width: auto;
            margin: 15px auto;
          }
          p {
            color: $mate-black;
            font-weight: 700;
            font-size: 1.1em;
            @media #{$max-wid-device} {
              font-size: 14px;
            }
          }
          > span {
            color: $mate-black;
            display: flex;
            flex-wrap: wrap;
            @media #{$max-wid-device} {
              font-size: 14px;
            }

            @media #{$small-device} {
              justify-content: center;
              align-items: center;
            }
          }
        }

        .delete__stage {
          cursor: pointer;
        }
      }
    }

    .play__button {
      @include flex-center;
      a {
        background-color: $themecolor;
        color: $mate-black;
        padding: 10px 15px;

        @media (max-width: 340px) {
          font-size: 12px;
        }
      }
    }
  }

  .nav-link {
    color: #000;
    text-transform: uppercase;
    letter-spacing: 1.5px;
  }

  .nav-tabs {
    .nav-link {
      &.active {
        color: $themecolor;
      }
    }
  }
}
